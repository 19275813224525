<template>
  <b-container fluid="">
    <div class="d-flex flex-wrap align-items-center justify-content-between my-schedule mb-4">
      <div class="d-flex align-items-center justify-content-between">
        <h4 class="font-weight-bold">Slides</h4>
      </div>
      <div class="create-workform">
        <div class="d-flex flex-wrap align-items-center justify-content-between">
          <div class="modal-product-search d-flex">
            <div class="form-group mr-3">
              <input type="date" class="form-control" id="exampleInputdate"
                     v-model="filter_min_date">
            </div>
            <div class="form-group mr-3">
              <input type="date" class="form-control" id="exampleInputdate2"
                     v-model="filter_max_date">
            </div>
            <button :disabled="loading_charts" @click="getTimeLineCharts" type="button"
                    class="btn btn-primary position-relative d-flex align-items-center justify-content-between svg-icon">
              <i class="ri-bill-fill"></i>Filtrar
            </button>
          </div>
        </div>
      </div>
    </div>
    <h4 class="mt-2 mb-4"><b>Uso del dominio <span class="text-blue" v-text="auth_user.domain_user"></span></b> <small>del <b>{{ format_min_date }}</b> al  <b>{{ format_max_date }}</b></small></h4>
    <div v-if="loading_charts">
      <b-spinner variant="primary" label="Spinning"></b-spinner> Cargando, espere...
    </div>
    <div v-else>
      <b-row>
        <b-col lg="4" md="12" sm="12" xs="12">
          <card>
            <template v-slot:body>
              <div>
                <ApexChart :element="`chart-activity`" :chartOption="activityChart"/>
                <div class="d-flex justify-content-around align-items-center">
                  <div>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="#ffbb33" xmlns="http://www.w3.org/2000/svg">
                      <rect id="Rectangle 79" x="3" y="3" width="18" height="18" rx="2" fill="#952f81"/>
                    </svg>
                    <span>Docentes</span>
                  </div>
                  <div>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="#e60000" xmlns="http://www.w3.org/2000/svg">
                      <rect id="Rectangle 79" x="3" y="3" width="18" height="18" rx="2" fill="#64c3d1"/>
                    </svg>
                    <span>Estudiantes</span>
                  </div>
                  <div>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="#e60000" xmlns="http://www.w3.org/2000/svg">
                      <rect id="Rectangle 79" x="3" y="3" width="18" height="18" rx="2" fill="#f9d94c"/>
                    </svg>
                    <span>Otros</span>
                  </div>
                </div>
              </div>

            </template>
          </card>
        </b-col>

        <b-col v-for="(chart,index) in chartsTimeLine" :lg="(index<=0)?8:6" md="12" sm="12" xs="12" :key="index">
          <card>
            <template v-slot:body>
              <ApexChart :element="`chart-${index}`" :chartOption="chart.bodyData"/>
            </template>
          </card>
        </b-col>

        <b-col lg="12" md="12" sm="12" xs="12">
          <card>
            <template v-slot:body v-if="teachers.length>0">
              <!--tabla de usuarios-->
              <p>Listado de docentes</p>
              <b-table
                  :current-page="current_page_teachers"
                  :per-page="perPage"
                  :items="teachers"
                  :busy="isBusy"
                  class="mt-3" outlined>
                <template #table-busy>
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>Loading...</strong>
                  </div>
                </template>
              </b-table>
              <b-pagination
                  v-model="current_page_teachers"
                  :total-rows="total_rows_teachers"
                  :per-page="perPage"
                  align="fill"
                  size="sm"
                  class="my-0"
              ></b-pagination>
            </template>
            <div class="pt-3 pl-3 m-0" v-else>
              <p>No se encontraron resultados para mostrar el listado de <strong>Docentes</strong></p>
            </div>
          </card>
        </b-col>

        <b-col lg="12" md="12" sm="12" xs="12">
          <card>
            <template v-slot:body v-if="students.length>0">
              <!--tabla de usuarios-->
              <p>Listado de estudiantes</p>
              <b-table
                  :current-page="current_page_students"
                  :per-page="perPage"
                  :items="students"
                  :busy="isBusy"
                  class="mt-3" outlined>
                <template #table-busy>
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>Loading...</strong>
                  </div>
                </template>
              </b-table>
              <b-pagination
                  v-model="current_page_students"
                  :total-rows="total_rows_students"
                  :per-page="perPage"
                  align="fill"
                  size="sm"
                  class="my-0"
              ></b-pagination>
            </template>
            <div class="pt-3 pl-3 m-0" v-else>
              <p>No se encontraron resultados para mostrar el listado de <strong>estudiantes</strong></p>
            </div>
          </card>
        </b-col>

        <b-col lg="12" md="12" sm="12" xs="12">
          <card>
            <template v-slot:body v-if="others.length>0">
              <!--tabla de usuarios-->
              <p>Listado de otros</p>
              <b-table
                  :current-page="current_page_others"
                  :per-page="perPage"
                  :items="others"
                  :busy="isBusy"
                  class="mt-3" outlined>
                <template #table-busy>
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>Loading...</strong>
                  </div>
                </template>
              </b-table>
              <b-pagination
                  v-model="current_page_others"
                  :total-rows="total_rows_others"
                  :per-page="perPage"
                  align="fill"
                  size="sm"
                  class="my-0"
              ></b-pagination>
            </template>
            <div class="pt-3 pl-3 m-0" v-else>
              <p>No se encontraron resultados para mostrar el listado de <strong>Otros</strong></p>
            </div>
          </card>
        </b-col>

      </b-row>
    </div>

  </b-container>
</template>
<script>
import ApexChart from '../../../components/charts/ApexChart'
import moment from "moment";

export default {
  props: ['auth_user'],
  name: 'Classroom',
  components: {
    ApexChart
  },

  data() {
    return {
      teachers_table: false,
      students_table: false,
      others_table: false,
      teachers: [],
      students: [],
      others: [],
      total_rows_students: 0,
      total_rows_teachers: 0,
      total_rows_others: 0,
      // currentPage: 1,
      current_page_students: 1,
      current_page_teachers: 1,
      current_page_others: 1,
      perPage: 5,
      pageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
      isBusy: false,
      loading_charts: true,
      filter_min_date: '2021-10-01',
      filter_max_date: '2021-10-31',
      format_min_date: '',
      format_max_date: '',
      activityChart: null,
      chartsTimeLine: []
    }
  },

  mounted() {
    this.getTimeLineCharts()
  },
  methods: {
    formatRangeDates() {
      this.format_min_date = moment(this.filter_min_date, "YYYY-MM-DD").format("DD/MMM/YYYY")
      this.format_max_date = moment(this.filter_max_date, "YYYY-MM-DD").format("DD/MMM/YYYY")
    },
    getTimeLineCharts() {
      this.formatRangeDates()
      this.loading_charts = true
      this.$api.get(`/api/user-usage/get-activity-slides?start_date=${this.filter_min_date}&end_date=${this.filter_max_date}`)
          .then(res => {
            this.drawLineCharts(res.data.data.donut)
            this.drawLineChartsTimeline(res.data.data.timeline)
            this.teachers = res.data.data.list_users.teachers
            this.students = res.data.data.list_users.students
            this.others = res.data.data.list_users.others
            this.total_rows_students = this.students.length
            this.total_rows_teachers = this.teachers.length
            this.total_rows_others = this.others.length
          })
          .catch(err => {
            console.log(err)
          })
          .finally(() => {
            this.loading_charts = false
          })
    },
    formatDateByArray(dates = []) {
      let dates_converted = []
      for ( let d = 0; d < dates.length; d++ )
      {
        dates_converted.push(moment(dates[d], "YYYY-MM-DD").format("DD/MMM/YYYY"))
      }
      return dates_converted
    },
    drawLineChartsTimeline(charts_data = []) {
      console.log(charts_data)
      this.chartsTimeLine[0] = {
        title: '',
        type: 'line',
        bodyData: {
          chart: {
            height: 385,
            type: 'line',
            zoom: {
              enabled: false
            }
          },
          series: [{
            name: charts_data.teachers.label,
            data: charts_data.teachers.data.totals
          }],
          dataLabels: {
            enabled: false
          },
          stroke: {
            curve: 'smooth'
          },
          title: {
            text: charts_data.teachers.description,
            align: 'left'
          },
          grid: {
            row: {
              colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
              opacity: 0.5
            }
          },
          xaxis: {
            categories: this.formatDateByArray(charts_data.teachers.data.dates)
          },
          yaxis: [
            {
              labels: {
                formatter: function(val) {
                  return val.toFixed(0);
                }
              }
            }
          ]
        }
      }

      this.chartsTimeLine[1] = {
        title: '',
        type: 'line',
        bodyData: {
          chart: {
            height: 385,
            type: 'line',
            zoom: {
              enabled: false
            }
          },
          series: [{
            name: charts_data.students.label,
            data: charts_data.students.data.totals
          }],
          dataLabels: {
            enabled: false
          },
          stroke: {
            curve: 'smooth'
          },
          title: {
            text: charts_data.students.description,
            align: 'left'
          },
          grid: {
            row: {
              colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
              opacity: 0.5
            }
          },
          xaxis: {
            categories: this.formatDateByArray(charts_data.students.data.dates)
          },
          yaxis: [
            {
              labels: {
                formatter: function(val) {
                  return val.toFixed(0);
                }
              }
            }
          ]
        }
      }

      this.chartsTimeLine[2] = {
        title: '',
        type: 'line',
        bodyData: {
          chart: {
            height: 385,
            type: 'line',
            zoom: {
              enabled: false
            }
          },
          series: [{
            name: charts_data.others.label,
            data: charts_data.others.data.totals
          }],
          dataLabels: {
            enabled: false
          },
          stroke: {
            curve: 'smooth'
          },
          title: {
            text: charts_data.others.description,
            align: 'left'
          },
          grid: {
            row: {
              colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
              opacity: 0.5
            }
          },
          xaxis: {
            categories: this.formatDateByArray(charts_data.others.data.dates)
          },
          yaxis: [
            {
              labels: {
                formatter: function(val) {
                  return val.toFixed(0);
                }
              }
            }
          ]
        }
      }

      /*this.chartsTimeLine[2] = {
        title: '',
        type: 'line',
        bodyData: {
          chart: {
            height: 385,
            type: 'line',
            zoom: {
              enabled: false
            }
          },
          series: [{
            name: 'Estudiantes',
            data: charts_data.others.data.totals
          }],
          dataLabels: {
            enabled: false
          },
          stroke: {
            curve: 'smooth'
          },
          title: {
            text: charts_data.others.description,
            align: 'left'
          },
          grid: {
            row: {
              colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
              opacity: 0.5
            }
          },
          xaxis: {
            categories: charts_data.others.data.dates
          },
          yaxis: [
            {
              labels: {
                formatter: function(val) {
                  return val.toFixed(0);
                }
              }
            }
          ]
        }
      }*/
    },
    drawLineCharts(charts_data = []) {
      this.activityChart = {
        series: charts_data.numbers,
        chart: {
          height: 410,
          type: 'donut',
        },
        labels: charts_data.labels,
        colors: ['#952f81', '#64c3d1', '#f9d94c'],
        plotOptions: {
          pie: {
            startAngle: -90,
            endAngle: 270,
            donut: {
              size: '80%',
              labels: {
                show: true,
                total: {
                  show: true,
                  color: '#BCC1C8',
                  fontSize: '18px',
                  fontFamily: 'DM Sans',
                  fontWeight: 600,
                },
                value: {
                  show: true,
                  fontSize: '25px',
                  fontFamily: 'DM Sans',
                  fontWeight: 700,
                  color: '#8F9FBC',
                },
              }
            }
          }
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          lineCap: 'round'
        },
        grid: {
          padding: {

            bottom: 0,
          }
        },
        legend: {
          position: 'bottom',
          offsetY: 8,
          show: true,
        },
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              height: 268
            }
          }
        }]
      }
    },
    generateData(baseval, count, yrange) {
      var i = 0
      var series = []
      while (i < count) {
        var y = Math.floor(Math.random() * (yrange.max - yrange.min + 1)) + yrange.min
        var z = Math.floor(Math.random() * (75 - 15 + 1)) + 15

        series.push([baseval, y, z])
        baseval += 86400000
        i++
      }
      return series
    }
  },
}
</script>
